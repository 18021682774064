ion-thumbnail {
    width: 100px;
    height: 100px;
}

ion-thumbnail img {
    width: auto !important;
    height: auto !important;
}

.item.active, .item.activated, .item-complex.active .item-content, .item-complex.activated .item-content, .item .item-content.active, .item .item-content.activated {
  border-color: #ccc;
  background-color: #ff0000;
}

ion-item {
    --background-hover: #fff;
    --background-focused: #fff;
    --ripple-color: #fff;
    --background-activated: #fff;
}
