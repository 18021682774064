.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 25px;
    color: #8c8c8c;
}

.container p strong {
    font-size: 20px;
    line-height: 26px;
}

.container p {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

.container a {
    text-decoration: none;
}

ion-item ion-label {
    white-space: normal !important;
}
