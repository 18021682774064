.product-img {
    max-width: 400px !important;
    height: auto;
    margin: auto;
    display: block;
}

.item.active, .item.activated, .item-complex.active .item-content, .item-complex.activated .item-content, .item .item-content.active, .item .item-content.activated {
  border-color: #ccc;
  background-color: #ff0000;
}
